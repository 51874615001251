import React, { useEffect } from "react";
//import MainMenuController from "../js/MainMenuController";
import "@popperjs/core";

import "../../../styles/main.scss";

import czechinvest from "../../../images/products/msearch/technology_incubation_logo_cz.png";
import logo from "../../../images/mamaai_logo--white.svg";
import example1cz from "../../../images/products/msearch/example-1-cz.png";
import example2cz from "../../../images/products/msearch/example-2-cz.png";
import personCalling from "../../../images/products/msearch/person-calling.jpg";
import personComputer from "../../../images/products/msearch/person-on-computer.jpg";
import returnOnInvestment from "../../../images/products/msearch/return-on-investment.png";

import Metadata from "../../../js/Metadata";
import Navbar from "../../../js/Navbar";
import Footer from "../../../js/Footer";
import AiAcademyToast from "../../../js/layout/ai_academy_toast";

import Swal from "sweetalert2";

const MSearchPage = () => {
    const contactFormUrl =
        "https://mlh0rbexm3.execute-api.us-east-2.amazonaws.com/default/ContactUsLog?TableName=ContactUsLogs";
    //const contactFormUrl = "https://httpbin.org/post"

    const description =
        "mSearch je produktem firmy The MAMA AI, pomocí kterého budete vyhledávat informace přirozeným jazykem.";

    async function fetchUrl(url, data) {
        const response = await fetch(url, {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, cors, *same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "omit", // include, *same-origin, omit
            headers: {
                "Content-Type": "application/json",
            },
            redirect: "follow", // manual, *folslow, error
            referrer: "client", // no-referrer, *client
            body: JSON.stringify(data), // body data type must match "Content-Type" header
        });
        await response.json();
        Swal.fire({
            title: "Thank you!",
            text: "Your message has been sent!",
            icon: "success",
            confirmButtonColor: "#FAA847",
        });
    }

    useEffect(() => {
        const handleDemoSubmit = (event) => {
            event.preventDefault();

            const contactFormData2 = {
                Item: {
                    topic: {
                        S: "Request a demo",
                    },
                    email: {
                        S: document.getElementById("sender-email2").value,
                    },
                    message: {
                        S: document.getElementById("message-text2").value,
                    },
                },
                TableName: "ContactUsLogs",
            };

            fetchUrl(contactFormUrl, contactFormData2);
        };
        document
            .getElementById("submit-btn2")
            .addEventListener("click", handleDemoSubmit);
    }, []);

    return (
        <div className="container-max-width">
            <Metadata
                title="THE MAMA AI mSearch"
                url="https://themama.ai/products/msearch/cz"
                description={description}
            />
            <Navbar />
            <section className="ai-logo-section section g-0 pb-5">
                <div>
                    <div id="home" className="section__anchor" />
                    <header className="text-center logo-header m-auto">
                        <a href="/#home">
                            <img
                                className="logo-header__logo d-block m-auto"
                                src={logo}
                                alt="The mama AI"
                            />
                        </a>
                        <h1 className="fs-3 pt-5 text--color-2">mSearch</h1>
                        <a
                            href="/products/msearch/en"
                            className="english-btn btn-primary text-white btn m-auto mt-3 text-decoration-none"
                            role="button"
                        >
                            English version
                        </a>
                    </header>
                </div>
            </section>
            <section className="section msearch-details-section pb-5 section--light pt-5">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <h1 className="pt-3 text-center">
                                od vyhledávání k porozumění
                            </h1>
                            <p className="pt-3 text-center subtitle">
                                mSearch je produktem firmy The MAMA AI, pomocí
                                kterého budete vyhledávat informace přirozeným
                                jazykem.
                            </p>
                            <p className="pt-4">
                                Jak může vaše vyhledávání informací vypadat? Na
                                položený dotaz dostanete správnou odpověď, i
                                když se v dotazu nevyskytuje žádné ze slov,
                                které je obsaženo v nalezené odpovědi.
                            </p>
                        </div>
                        <div className="col-lg-11 pt-3 pb-3">
                            <img
                                src={example1cz}
                                alt="Example 1 in Czech"
                                className="img-fluid rounded"
                            />
                        </div>
                        <div className="col-lg-8 position-relative">
                            <p className="pt-3">
                                Technologie porozumění přirozenému jazyku (NLU)
                                zvyšuje efektivitu a komfort uživatele v těchto
                                oblastech:
                            </p>
                            <ul className="custom-ul">
                                <li>
                                    vyhledávání v rozsáhlých databázích
                                    dokumentů – spisová služba, normy, popisy
                                    procesů, dokumentace pracovních postupů či
                                    použitá řešení údržby apod.
                                </li>
                                <li>
                                    porozumění požadavku klienta - i pokud
                                    požadavek není odborně zcela správně, přesto
                                    může být klient nasměrován na správný
                                    dokument, pracovníka nebo oddělení
                                </li>
                                <li>
                                    Human-to-Digital, ale i Digital-to-Digital
                                    komunikace
                                </li>
                            </ul>
                            <p className="pt-4">
                                Nezáleží na použité diakritice a dokonce může
                                být dotaz položen v jiném jazyce než je
                                prohledávaná databáze. Sémantické vyhledávání je
                                jazykově nezávislé.
                            </p>
                        </div>
                        <div className="col-lg-11 pt-3 pb-3">
                            <figure className="figure">
                                <img
                                    src={example2cz}
                                    alt="Vysvětlení: Rumunská otázka „Ce drumuri sunt periculoase?“ znamená česky „Které silnice jsou nebezpečné?“"
                                    className="img-fluid figure-img rounded"
                                />
                                <figcaption className="figure-caption text-center">
                                    Vysvětlení: Rumunská otázka „Ce drumuri sunt
                                    periculoase?“ znamená česky „Které silnice
                                    jsou nebezpečné?“
                                </figcaption>
                            </figure>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section msearch-details-section pb-5 pt-5">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-12 pt-3">
                            <div className="row featurette pt-5 justify-content-center">
                                <div className="col-lg-10 col-xl-8">
                                    <blockquote className="blockquote text-center text--color-2">
                                        <h1>
                                            Jak jsme to tehdy řešili..?
                                            <br />
                                            Tohle onehdy taky někdo chtěl…{" "}
                                            <br />
                                            Takovou situaci už jsem někde viděl…
                                        </h1>
                                    </blockquote>
                                    <p className="text-center subtitle">
                                        mSearch vám pomůže nadchnout vaše
                                        klienty například v následujících
                                        situacích:
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="row featurette pt-5 justify-content-center">
                                <div className="col-lg-6 col-xl-4 align-self-center order-lg-2">
                                    <p>
                                        Požadavek volajícího klienta bude v
                                        kontaktním centru bez čekání správně
                                        pochopen a poskytne operátorovi
                                        relevantní informace ještě před začátkem
                                        hovoru s klientem. Třeba o tom, jak byly
                                        podobné požadavky úspěšně řešeny v
                                        minulosti.{" "}
                                    </p>
                                    <p className="pt-3">
                                        A někdy bude s klientem vyřešen i bez
                                        předání operátorovi.
                                    </p>
                                </div>
                                <div className="col-lg-4 col-xl-3 order-lg-1">
                                    <img
                                        src={personCalling}
                                        alt="Person calling"
                                        className="img-fluid rounded"
                                    />
                                </div>
                            </div>
                            <div className="row featurette pt-5 justify-content-center">
                                <div className="col-lg-6 col-xl-4 align-self-center">
                                    <p>
                                        Potřebujete vyhledat ve firemních
                                        dokumentech situaci podobnou té, kterou
                                        řešíte pro klienta, ale třeba popsanou
                                        jinými slovy? Například kolize
                                        automobilistů se zvířaty? mSearch vám
                                        vyhledá podobné situace, ať se jedná o
                                        řešení IT problému nebo pojistné
                                        události. A nemusíte vědět, v jakém
                                        jazyce je hledaný dokument, abyste ho
                                        našli.
                                    </p>
                                </div>
                                <div className="col-xl-3 col-lg-4">
                                    <img
                                        src={personComputer}
                                        alt="Person on computer"
                                        className="img-fluid rounded"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 col-xl-6 pt-5">
                            <blockquote className="blockquote text-center">
                                <p className="text--color-2">
                                    „Při projíždění oplocenou pastvinou na mé
                                    auto zaútočil býk.“
                                </p>
                                <p className="text--color-2">
                                    „Jelena se mi na noční cestě podařilo
                                    minout, ale pohodil hlavou a rozbil mi
                                    parožím zrcátko a poškrábal dveře.“
                                </p>
                                <p className="text--color-2">
                                    „Snažil jsem se tomu psovi vyhnout.“
                                </p>
                            </blockquote>
                        </div>
                    </div>
                    <div className="row justify-content-center pt-3">
                        <div className="col-lg-4 order-lg-2 align-self-center">
                            <p className="text-center">
                                Zajímá vás návratnost vašeho AI projektu? <br />{" "}
                                Rádi s vámi projdeme konkrétní data, abychom
                                zjistili, od kterého měsíce vám váš AI projekt
                                začne vydělávat.
                            </p>
                        </div>
                        <div className="col-lg-6 col-xl-5 pt-3 pb-3 order-lg-1 text-center">
                            <img
                                src={returnOnInvestment}
                                alt="mAI platform Return on Investment"
                                className="img-fluid rounded"
                            />
                        </div>
                    </div>
                </div>
            </section>
            <section className="section pb-5 pt-5 msearch-details-section section--light contact-us-section">
                <div className="container px-8">
                    <div className="row justify-content-md-center">
                        <div className="col-12 col-lg-6 align-self-center">
                            <h1 className="pt-1">
                                Umíme dodat i technologie, které vám umožní
                                komunikovat hlasem.
                            </h1>
                            <h2 className="pt-3">
                                Vlastně máme řadu skvělých AI produktů. Chtěli
                                byste nějaké?
                            </h2>
                        </div>
                        <div className="col-12 col-lg-4 offset-xl-1">
                            <form>
                                <h1
                                    className="modal-title"
                                    id="requestADemoLabel"
                                >
                                    Chci vidět demo
                                </h1>
                                <div className="mb-3">
                                    <label
                                        htmlFor="sender-email2"
                                        className="col-form-label"
                                    >
                                        E-mail:
                                        <span className="form-asterisk">*</span>
                                    </label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="sender-email2"
                                        required
                                    />
                                    <label
                                        htmlFor="message-text2"
                                        className="col-form-label"
                                    >
                                        Zpráva: (nepovinné)
                                    </label>
                                    <textarea
                                        className="form-control"
                                        id="message-text2"
                                        rows="3"
                                    ></textarea>
                                </div>
                                <button
                                    type="submit"
                                    className="btn btn-primary text-white main-navbar__demo-button modal-button"
                                    id="submit-btn2"
                                >
                                    Odeslat
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section pb-3 msearch-details-section section--light msearch-footer-section">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-auto">
                            <img
                                src={czechinvest}
                                alt="Czech Invest"
                                className="img-fluid msearch-footer-section__logo msearch-footer-section__logo--cz"
                            />
                        </div>
                        <div className="col-12 col-md-6 col-lg-5 col-xl-4">
                            <p className="mb-0 mt-4 mt-md-2 mt-lg-3">
                                Tento projekt byl realizován za finanční podpory
                                programu Technologická inkubace.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <AiAcademyToast isCzechPage={true} />
            <Footer />
        </div>
    );
};

export default MSearchPage;
